:root {
  --black-color: #343434;
}

.dashboard-container-3 {
  display: flex;
  flex-direction: row;
  max-width: 832px;
  margin: auto;
}

.dashboard-p-24 {
  padding: 24px;
}

.dashboard-md-flex-column {
  flex-direction: column;
}

.dashboard-md-flex-row {
  flex-direction: row;
}

.dashboard-gap-12 {
  gap: 12px;
}

.dashboard-gap-16 {
  gap: 16px;
}

.dashboard-gap-32 {
  gap: 32px;
}

.dashboard-md-gap-32 {
  gap: 32px;
}

.dashboard-w-30 {
  /*
  width: calc(100% / 3);
  */
  width: 15%;
}

.dashboard-flex {
  display: flex;
}

.dashboard-flex-column {
  flex-direction: column;
}

.dashboard-mt-24 {
  margin-top: 24px;
}

.dashboard-md-w-full {
  width: 100%;
}
.dashboard-pr-24 {
  padding-right: 24px;
}

.dashboard-border-r-grey {
  border-right: 1px solid #f5f5f5;
}

.dashboard-mb-12 {
  margin-bottom: 12px;
}

.dashboard-info-desc {
  font-size: 14px;
}

.dashboard-mb-0 {
  margin-bottom: 0;
}

.dashboard-m-0 {
  margin: 0;
}

.dashboard-info-detail {
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 0;
  align-items: end;
}

.dashboard-gap-8 {
  gap: 8px;
}

.dashboard-heading-xl {
  font-size: 40px;
}

.dashboard-w-70 {
  width: 70%;
}

.dashboard-grid {
  display: grid;
}

.dashboard-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.dashboard-gap-y-32 {
  row-gap: 32px;
}

.dashboard-gap-x-8 {
  column-gap: 8px;
}

.dashboard-mt-24 {
  margin-top: 24px;
}

.dashboard-md-grid-cols-2 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.dashboard-w-full {
  width: 100%;
}

.dashboard-custom-progress {
  justify-content: center;
  display: flex;
}

.dashboard-custom-progress {
  color: var(--black-color) !important;
}

.dashboard-flex-row {
  flex-direction: row;
}
.dashboard-middle {
  align-items: center;
}

.dashboard-content-center {
  justify-content: center;
}
.dashboard-hover-pointer:hover {
  cursor: pointer;
}
.dashboard-text-center {
  text-align: center;
}
.dashboard-content-space-between {
  justify-content: space-between;
}

.dashboard-text-sm {
  font-size: 14px;
}
.dashboard-m-0 {
  margin: 0;
}
.dashboard-font-normal,
.dashboard-font-normal label {
  font-weight: 400 !important;
}

.min-h-72 {
  min-height: 72px;
}

.min-h-104 {
  min-height: 104px;
}

.dashboard-skeleton-dashboard {
  margin-block-start: 8px !important;
  margin: 0;
}

.dashboard-skeleton-dashboard {
  margin: 0;
}
.dashboard-p-12-0 {
  padding: 12px 0;
}

.dashboard-is-money {
  font-size: 42px;
}

.dashboard-total-size {
  font-size: 46px;
}
